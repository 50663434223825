<template>
  <div class="data-log">
    <span v-if="data.receivedAt">Stock in time: {{ formatDateFromBE(data.receivedAt) }}</span>
    <span v-if="data.quantity">Quantity: {{ data.quantity }}</span>
    <span v-if="data.unitPrice">Stock in price: {{ data.unitPrice }}</span>
    <span v-if="data.supplierName">Supplier: {{ data.supplierName }}</span>
    <span v-if="data.description">Description: {{ data.description }}</span>
  </div>
</template>

<script>
import { formatDateFromBE } from '@/utils/format'

export default {
  methods: { formatDateFromBE },
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.data-log {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #626262;
  width: 350px;
}
</style>
