

































































































import { apiManagement } from '@/services/apis/apiManagement'
import {formatDateFromBE, formatDateTimeFromBE} from "@/utils/format";
import LogDetailDataItem from "@/components/inventory/LogDetailDataItem.vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default {
  components: {LogDetailDataItem},
  data() {
    return {
      skuFilter: null,
      skuOptions: [],
      queryData: {
        limit: 50,
        offset: 0,
        orderBy: 'createdTime',
        orderType: 'DESC',
        'filters[productVariantId]': null,
      },
      listData: {
        data: [],
        count: 0
      },
      paginationData: {
        currentPage: 1,
        length: 1
      }
    }
  },

  async created() {
    await this.initialize()
  },

  methods: {
    formatDateFromBE,
    formatDateTimeFromBE,
    async initialize() {
      await Promise.all([this.getAllSkuOptions(), this.getListLog()])
    },

    async getAllSkuOptions() {
      try {
        const response = await apiManagement.getAllSkuOptions()
        this.skuOptions = response.data
      } catch (e) {
        console.log(e)
      }
    },

    async getListLog() {
      try {
        this.queryData.offset = (this.paginationData.currentPage - 1) * this.queryData.limit
        const response = await apiManagement.getListHistory(this.queryData)
        this.listData = response.data
        this.paginationData.length = Math.ceil(response.data.count / this.queryData.limit)
      } catch (e) {
        console.log(e)
      }
    },

    changeFilterSKU() {
      this.getListLog()
    },

    changePage() {
      this.getListLog()
    },

    getLogLabelByType(type: string) {
      const labels = {
        Delete: 'Delete',
        Update: 'Update Info',
        Create: 'Add stock',
        Import: 'Add stock'
      }

      return labels[type]
    },

    downloadFileImport(logFileData) {
      if (logFileData) {
        coreApiClient.download("actionLogs", `${logFileData.logId}/files/${logFileData.fileInfo.uuid}`);
      }
    },
  }
}
